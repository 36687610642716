import React from 'react';
import '../styles/cards.css';
import nj from '../assets/nj.png';
import us from '../assets/us.png';

export default class ChaplainCard extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>Spring 2021 Membership Agreements</h2>
				<a
					href='https://drive.google.com/drive/folders/177gcg3uZ1WPakYLxpxgQX0GYNXSwFxdq?usp=sharing'
					target='_blank'
					className='chaplainMemAgreeContainer'
				>
					<img className='chaplainMemAgreeLogo' src='https://www.clio.com/wp-content/uploads/2018/07/image-7-800x715.webp' />
					<h4 className='chaplainMemAgreeText'>Click to view in Drive</h4>
				</a>

				<p></p>

				<h2 className='generalHeader'>Bylaws</h2>
				<div className='chaplainBylawWrapper'>
					<a
						href='https://docs.google.com/document/d/1zPiC2N02di9hA7XGIkEFCn2ZznjR15mV-lutUQbirc8/edit?usp=sharing'
						target='_blank'
						className='chaplainBylawContainer'
					>
						<img className='chaplainBylawLogo' src={nj} />
						<h4 className='chaplainBylawText'>Local</h4>
					</a>
					<a
						href='http://sigep.org/wp-content/uploads/2018/02/SigEp-Bylaws-Feb.-2018.pdf'
						target='_blank'
						className='chaplainBylawContainer'
					>
						<img className='chaplainBylawLogo' src={us} />
						<h4 className='chaplainBylawText'>National</h4>
					</a>
				</div>
			</div>
		);
	}
}
