import React from 'react';
import '../styles/cards.css';

export default class CommsCard extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>Current Brother List</h2>
				<iframe
					className='programmingCalendar'
					src='https://docs.google.com/spreadsheets/d/e/2PACX-1vSlXm3z3GP8zV8N2-nY87ch6fSgsaCrXWZjeu0BJOupcaOQyQXJfS7AOCeUFlXMr2KPyaJHgW8vRUXR/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false'
				></iframe>

				<h2 className='generalHeader'>Alumni List</h2>
				<iframe
					className='programmingCalendar'
					src='https://docs.google.com/spreadsheets/d/e/2PACX-1vQmengLzBicPp3l-bRPgM5nkJ2nqIfiDAvq538AZIoaAn8vPNrdl2o1HhjWD9ezX3UUvn-2sgKpU71C/pubhtml?gid=1353200754&amp;single=true&amp;widget=true&amp;headers=false'
				></iframe>
			</div>
		);
	}
}
