import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import '../styles/dynCard.css'
import arrow from '../assets/arrow.png'
import GeneralCard from './generalCard'
import ProgrammingCard from './programmingCard'
import CommsCard from './commsCard';
import MemDevCard from './memdevCard'
import RecruitmentCard from './recruitmentCard'
import FinanceCard from './financeCard'
import ChaplainCard from './chaplainCard'

export default class DynCard extends React.Component {
    state = {
        index: 0,
        mobileHeaderText: "General",
        mobileListIsExpanded: false,
        mobileListHeight: '0px',
        mobileListVisibility: 'hidden',
        mobileListOpacity: 0
    }

    componentDidMount() {
        this.checkPath()
    }

    updateCardIndex( index, mobileText ) {
        this.setState({
            index: index,
            mobileHeaderText: mobileText
        }, () => { this.updateTabs(index) })
        
    }

    checkPath() {
        // Check path so tab and dynCard match
        var path = window.location.pathname
        if ( path === '/' ) {
            this.setState({
                index: 0,
                mobileHeaderText: "General"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/programming' ) {
            this.setState({
                index: 1,
                mobileHeaderText: "Programming"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/memdev' ) {
            this.setState({
                index: 2,
                mobileHeaderText: "Member Development"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/recruitment' ) {
            this.setState({
                index: 3,
                mobileHeaderText: "Recruitment"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/finance' ) {
            this.setState({
                index: 4,
                mobileHeaderText: "Finance"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/chaplain' ) {
            this.setState({
                index: 5,
                mobileHeaderText: "Chaplain/Standards"
            }, () => { this.updateTabs(this.state.index) })
        }
        else if ( path === '/comms' ) {
            this.setState({
                index: 6,
                mobileHeaderText: "Communications"
            }, () => { this.updateTabs(this.state.index) })
        }
    }
    updateTabs( index ) {
        var arrayOfTabs = document.getElementsByClassName("dynCardTab");
        for ( var i = 0; i < arrayOfTabs.length; i++ ) {
            if ( i === index ) {
                arrayOfTabs[i].className = "dynCardTab is-open";
            }
            else {
                arrayOfTabs[i].className = "dynCardTab is-closed";
            }
        }
    }

    /* -- Mobile Nav --*/
    toggleMobileNav() {
        if ( this.state.mobileListIsExpanded === false ) {
            var headerArrow = document.getElementById('mobileArrow')
            headerArrow.className = "dynCardMobileHeaderArrow is-expanded"
        
            this.setState({
                mobileListHeight: '1000px',
                mobileListVisibility: 'visible',
                mobileListOpacity: 1,
                
            }, () => {
                this.setState({mobileListIsExpanded: true})
            })
        } 
        else {
            var headerArrow = document.getElementById('mobileArrow')
            headerArrow.className = "dynCardMobileHeaderArrow"
        
            this.setState({
                mobileListOpacity: 0,
                
            }, () => {
                this.setState({mobileListIsExpanded: false})
            })
        }
        
    }

    render() {
        const mobileListIsExpanded = this.state.mobileListIsExpanded;
        return(
            <div className='dynCardMainContainer'>
                <div className='dynCardContentContainer'>

                    <div className='dynCardTabGrid'>
                        <Link to='/' onClick={() => { this.updateCardIndex(0, 'General') }} className='dynCardTab'>
                            <p className='dynCardTabText'>General</p>
                        </Link>
                        <Link to='/programming' onClick={() => { this.updateCardIndex(1, 'Programming') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Programming</p>
                        </Link>
                        <Link to='/memdev' onClick={() => { this.updateCardIndex(2, 'Member Development') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Member Development</p>
                        </Link>
                        <Link to='/recruitment' onClick={() => { this.updateCardIndex(3, 'Recruitment') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Recruitment</p>
                        </Link>
                        <Link to='/finance' onClick={() => { this.updateCardIndex(4, 'Finance') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Finance</p>
                        </Link>
                        <Link to='/chaplain' onClick={() => { this.updateCardIndex(5, 'Chaplain/Standards') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Chaplain/Standards</p>
                        </Link>
                        <Link to='/comms' onClick={() => { this.updateCardIndex(6, 'Communications') }} className='dynCardTab'>
                            <p className='dynCardTabText'>Communications</p>
                        </Link>
                    </div>

                    <div className="dynCardMobileWrapper">
                        <div className="dynCardMobileHeader">
                            <h3 className="dynCardMobileHeaderText">{ this.state.mobileHeaderText }</h3>
                            <img id='mobileArrow' src={ arrow } className="dynCardMobileHeaderArrow" onClick={() => { this.toggleMobileNav() }}/>
                        </div>
                        <div className={ mobileListIsExpanded ? 'dynCardMobileList' : 'dynCardMobileListCollapsed' } style={{maxHeight: this.state.mobileListHeight}}>
                            <Link to='/' onClick={() => { this.updateCardIndex(0, 'General'); this.toggleMobileNav()  }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">General</p>
                            </Link>
                            <Link to='/programming' onClick={() => { this.updateCardIndex(1, 'Programming'); this.toggleMobileNav() }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Programming</p>
                            </Link>
                            <Link to='/memdev' onClick={() => { this.updateCardIndex(2, 'Member Development'); this.toggleMobileNav()  }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Member Development</p>
                            </Link>
                            <Link to='/recruitment' onClick={() => { this.updateCardIndex(3, 'Recruitment'); this.toggleMobileNav()  }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Recruitment</p>
                            </Link>
                            <Link to='/finance' onClick={() => { this.updateCardIndex(4, 'Finance'); this.toggleMobileNav()  }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Finance</p>
                            </Link>
                            <Link to='/chaplain' onClick={() => { this.updateCardIndex(5, 'Chaplain/Standards'); this.toggleMobileNav()  }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Chaplain/Standards</p>
                            </Link>
                            <Link to='/comms' onClick={() => { this.updateCardIndex(6, 'Communications'); this.toggleMobileNav() }} className='dynCardMobileLink'>
                                <p className="dynCardMobileItem">Communications</p>
                            </Link>
                        </div>
                    </div>

                    <div className='dynCardContent'>
                        <Switch>
                            <Route path='/programming' exact component={ ProgrammingCard } />

                            <Route path='/memdev' exact component={ MemDevCard } />

                            <Route path='/recruitment' exact component={ RecruitmentCard } />

                            <Route path='/finance' exact component={ FinanceCard } />

                            <Route path='/chaplain' exact component={ ChaplainCard } />

                            <Route path='/comms' exact component={ CommsCard } />

                            <Route path='/' component={ GeneralCard } />
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}