import React from 'react';
import Iframe from 'react-iframe';
import '../styles/cards.css';

export default class ProgrammingCard extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>Google Calendar</h2>
				<a
					href='https://calendar.google.com/calendar/u/0/r?cid=p6tbfkllft26mlbafjtfilsm0o@group.calendar.google.com&cid=0tlg5m22iulk0b3iik06uhh2as@group.calendar.google.com&cid=c_mo2dsta3g1eu7agca75ksf11eg@group.calendar.google.com'
					target='_blank'
					className='chaplainMemAgreeContainer'
					style={{ marginBottom: '20px' }}
				>
					<h4 className='chaplainMemAgreeText'>Click to Add SigEp Calendars to your Google Calendar</h4>
				</a>
				<iframe
					className='programmingCalendar'
					src='https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=cDZ0YmZrbGxmdDI2bWxiYWZqdGZpbHNtMG9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MHRsZzVtMjJpdWxrMGIzaWlrMDZ1aGgyYXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=Y19tbzJkc3RhM2cxZXU3YWdjYTc1a3NmMTFlZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F6BF26&amp;color=%238E24AA&amp;color=%23D50000&amp;showTitle=0&amp;showNav=1&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=1&amp;mode=WEEK'
				></iframe>
			</div>
		);
	}
}
