import React from 'react';
import '../styles/cards.css';
import bmp from '../assets/bmp.png';

export default class MemDevCard extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>BMP App</h2>
				<a href='https://mysigep.force.com/BMP/login' target='_blank' className='memdevBMPContainer'>
					<img className='memdevBMPLogo' src={bmp} />
					<h4 className='memdevBMPText'>Access Here</h4>
				</a>
				<h2 className='generalHeader'>Challenge Coordinators</h2>
				<div className='memdevCoordinatorsContainer'>
					<div>
						<div className='memdevCoordinatorsHeader'>
							<h3>Sigma</h3>
						</div>
						<div className='memdevCoordinatorsNames'>
							<p>Jayden Pereira</p>
							<p>Michael Burgos</p>
						</div>
					</div>
					<div>
						<div className='memdevCoordinatorsHeader'>
							<h3>Phi</h3>
						</div>
						<div className='memdevCoordinatorsNames'>
							<p>Harrup Singh</p>
							<p>Nick Diaz</p>
						</div>
					</div>
					<div>
						<div className='memdevCoordinatorsHeader'>
							<h3>Epsilon</h3>
						</div>
						<div className='memdevCoordinatorsNames'>
							<p>Elias Frieling</p>
							<p>Ankur Vijayvargiya</p>
						</div>
					</div>
					<div>
						<div className='memdevCoordinatorsHeader'>
							<h3>Brother Mentor</h3>
						</div>
						<div className='memdevCoordinatorsNames'>
							<p>Brian Taglialavore</p>
							<p> </p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
