import React from 'react';
import '../styles/cards.css';

export default class RecruitmentCard extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>PNML</h2>
				<iframe
					className='programmingCalendar'
					src='https://docs.google.com/spreadsheets/d/e/2PACX-1vSZjzHXsKWDCmVac6Jtd71fW6EiUnzuJshrENqOXUFJc9b7plU2SssVWfcgxz-G3MXw7woRN_Aw9eaN/pubhtml?gid=915440305&amp;single=true&amp;widget=true&amp;headers=false'
				></iframe>
			</div>
		);
	}
}
