import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './components/main.js'
import Credits from './components/credits.js'

function App() {
  return (
    <Router>

      <Switch>
        <Route path='/credits' exact component={ Credits } />
        <Route path='/' component={ Main } />
      </Switch>

    </Router>
  );
}

export default App;
