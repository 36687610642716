/* global gapi */
import React from 'react';
import '../styles/main.css'
import logo from '../assets/NJA.png'
import DynCard from '../components/dynCard.js'
import Navbar from '../components/navbar.js'
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import listOfBrothers from '../assets/brothers.json'

export default class Main extends React.Component {
    state = {
        isSignedIn: false
    }

    didSignIn = (response) => {
        var userEmail = response.profileObj.email
        /* Go through each semesters list of brothers in order to authorize entry
        
          (Don't really like this brute force method but for such a small n the O(n)
          for a nested loop won't have that bad of an effect)
        */
        for ( var semester in listOfBrothers ) {
            for ( var brother in listOfBrothers[semester] ) {
                if ( listOfBrothers[semester][brother] === userEmail ) {
                    // Match found, continue to login and break loop
                    this.setState({
                        isSignedIn: true
                    })

                    break
                }
            }
        }
        // Match never found, tell user to retry
    }

    didLogout() {
        this.setState({
            isSignedIn: false
        })
    }

    render () {
        // Only displayed when logged in
        if ( this.state.isSignedIn === true ) {
            return(
                <div className='mainContainer'>

                    <Navbar logout={ () => { this.didLogout() } }/>

                    <div className='mainDynCardContainer'>
                        <DynCard/>
                    </div>

                </div>
            )
        }
        // Default state
        else {
            return (
                <div className='mainLoginContainer'>
                    <div className='mainLoginContent'>
                        <img src={ logo } className='mainLoginLogo'/>
                        <h2 className='mainLoginHeader'>Please Sign in With Your Stevens Email to Enter</h2>
                        <GoogleLogin
                            clientId='1062011534573-j7usgvd7os121efec8ipruakuj2bipom.apps.googleusercontent.com'
                            buttonText='Login'
                            onSuccess={ this.didSignIn }
                            isSignedIn={ true }
                            render={renderProps => (
                                <h2 onClick={renderProps.onClick} disabled={renderProps.disabled} className='mainGoogleLogout'>Sign In</h2>
                            )}
                        />
                    </div>
                </div>
            )
        }
    }
}

