import React from 'react';
import '../styles/cards.css';
import family from '../assets/2021SFamilyTree.svg';
import tree from '../assets/tree.png';

export default class Card extends React.Component {
	render() {
		return (
			<div className='generalMainContainer'>
				<h2 className='generalHeader'>Chapter Minutes</h2>
				<iframe
					className='generalGoogleDrive'
					src='https://drive.google.com/embeddedfolderview?id=1yfujylBj4VL0klDWEaN_6XOUtvcNMDKf&orderBy=namedesc#grid'
				></iframe>

				<h2 className='generalHeader'>Big/Little Families</h2>
				<a href={family} target='_blank' className='generalFamilyTreeContainer'>
					<img className='generalTreeLogo' src={tree} />
					<h4 className='generalTreeText'>Click to view Family Trees</h4>
				</a>

				<h2 className='generalHeader'>Site Feedback Form</h2>
				<a href='https://forms.gle/cy5wQ6C8zNpHjQLg7' target='_blank' className='chaplainMemAgreeContainer'>
					<h4 className='chaplainMemAgreeText'>Click to Give Feedback</h4>
				</a>
			</div>
		);
	}
}
