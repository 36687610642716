import React from 'react';
import '../styles/nav.css';
import { elastic as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import logo from '../assets/NJA.png';
import hamburger from '../assets/hamburger.png';

export default class Navbar extends React.Component {
	state = {
		mobileMenuExpanded: false,
	};

	toggleMobileMenu() {
		if (this.state.mobileMenuExpanded === false) {
			this.state.setState(
				{
					mobileMenuExpanded: true,
				},
				() => {
					console.log(this.state.mobileMenuExpanded);
				}
			);
		} else {
			this.state.setState(
				{
					mobileMenuExpanded: false,
				},
				() => {
					console.log(this.state.mobileMenuExpanded);
				}
			);
		}
	}

	render() {
		const mobileMenuExpanded = this.state.mobileMenuExpanded;
		return (
			<div>
				<div className='navbarMainContainer'>
					<div className='navbarLeftContainer'>
						<Link to='/credits' className='navbarLogoContainer'>
							<img src={logo} className='navbarLogo' />
						</Link>
					</div>

					<div className='navbarRightContainer'>
						<ul className='navbarLinkContainer'>
							<a href='https://stevens.sigep.org' target='_blank'>
								<h3 className='mainHeaderLinkText'>Local Website</h3>
							</a>

							<a href='https://sigep.org/' target='_blank'>
								<h3 className='mainHeaderLinkText'>Nationals Website</h3>
							</a>
							<a href='https://drive.google.com/drive/folders/0B2J5V8srNYlMdW8wcngwLTgwbms?usp=sharing' target='_blank'>
								<h3 className='mainHeaderLinkText'>Alpha</h3>
							</a>
							<GoogleLogout
								clientId='1062011534573-j7usgvd7os121efec8ipruakuj2bipom.apps.googleusercontent.com'
								onLogoutSuccess={this.props.logout}
								render={(renderProps) => (
									<h3 onClick={renderProps.onClick} disabled={renderProps.disabled} className='navbarGoogleLogout'>
										Logout
									</h3>
								)}
							/>
						</ul>
					</div>
				</div>

				<div className='navbarMainMobileContainer'>
					<div className='navbarLeftMobileContainer'>
						<Link to='/credits' className='navbarMobileLogoContainer'>
							<img src={logo} className='navbarMobileLogo' />
						</Link>
					</div>
					<div className='navbarRightMobileContainer'>
						<div
							className='navbarMobileMenuImgContainer'
							onClick={() => this.setState({ mobileMenuExpanded: !this.state.mobileMenuExpanded })}
						>
							<img src={hamburger} className='navbarMobileMenuImg' />
						</div>
					</div>
				</div>
				<div className={mobileMenuExpanded ? 'navbarMobileExpandedContent' : 'navbarMobileCollapsedContent'}>
					<ul className='navbarMobileContentList'>
						<h3
							onClick={() => {
								window.location.assign('https://stevens.sigep.org');
							}}
							className='navbarMobileLink'
						>
							Local Website
						</h3>
						<h3
							onClick={() => {
								window.location.assign('https://sigep.org/');
							}}
							className='navbarMobileLink'
						>
							Nationals Website
						</h3>
						<h3
							onClick={() => {
								window.location.assign('https://drive.google.com/drive/folders/0B2J5V8srNYlMdW8wcngwLTgwbms?usp=sharing');
							}}
							className='navbarMobileLink'
						>
							Alpha
						</h3>
						<GoogleLogout
							clientId='1062011534573-j7usgvd7os121efec8ipruakuj2bipom.apps.googleusercontent.com'
							onLogoutSuccess={this.props.logout}
							render={(renderProps) => (
								<h3 onClick={renderProps.onClick} disabled={renderProps.disabled} className='navbarMobileGoogleLink'>
									Logout
								</h3>
							)}
						/>
					</ul>
				</div>
			</div>
		);
	}
}
