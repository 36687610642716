import React from 'react';
import '../styles/cards.css'

export default class FinanceCard extends React.Component {
    render() {
        return(
            <div className='generalMainContainer'>
                <h2 className='generalHeader'>Expense Sheet</h2>
                <div className='chaplainMemAgreeContainer' onClick={ () => { window.location.href = 'https://drive.google.com/open?id=1W9AotUurh_bMP5u0hy2hv6Yq-mvcYk3N' } }>
                    <img className='chaplainMemAgreeLogo' src='https://www.clio.com/wp-content/uploads/2018/07/image-7-800x715.webp'/>
                    <h4 className='chaplainMemAgreeText'>Click to view in Drive</h4>
                </div>
                <p></p>
                <h2 className='generalHeader'>Fine Sheet</h2>
                <div className='chaplainMemAgreeContainer' onClick={ () => { window.location.href = 'https://drive.google.com/open?id=156jDfq80XQNkSE9OUiU3uM8FhXUCQUUW' } }>
                    <img className='chaplainMemAgreeLogo' src='https://www.clio.com/wp-content/uploads/2018/07/image-7-800x715.webp'/>
                    <h4 className='chaplainMemAgreeText'>Click to view in Drive</h4>
                </div>
            </div>
        )
    }
}