import React from 'react';
import '../styles/credits.css'
import { Link } from 'react-router-dom';

export default class Credits extends React.Component {
    render() {
        return(
            <div className='creditsContainer'>
                <h1 className='creditsText'>Made for the brothers of NJA.</h1>
                <h1 className='creditsText'>Proud to be.</h1>
                <p></p>
                <h2>VDBL, 1223</h2>
                <p></p>
                <p></p>
                <p></p>
                <Link to='/' className='creditsButton'>
                    <h3>Go Back</h3>
                </Link>
            </div>
        )
    }
}